import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import Card from '../components/elements/Card'
import { Link, useLocation } from 'react-router-dom'
import Banner from '../components/elements/Banner'
import { RecruitInfo } from '../types/Recruit'
import colors from '../utils/colors'
import { callGtag } from '../utils/ga'

interface Props {
    recruits: RecruitInfo[]
}
const Main: React.FC<Props> = ({ recruits }) => {
    const clientHeight = document.documentElement.clientHeight
    const location = useLocation()

    useEffect(() => {
        callGtag('채용 목록', location.pathname)
    }, [location.pathname])

    return (
        <Layout>
            <Banner src="/image/Banner.png" height={320}>
                <div>
                    갤럭틱
                    <br />
                    엔터테인먼트
                    <br />
                    재택 모니터링단
                    <br />
                    채용 지원 사이트
                </div>
            </Banner>
            <Header>
                <h1>채용정보</h1>
            </Header>
            <BodyContent>
                <CardBox>
                    {recruits.map((item) => (
                        <Link
                            to={`/recruit/${item.recruit_no}`}
                            key={item.recruit_no}
                        >
                            {/* item.visibility 처리 해줘야함 */}
                            <Card src={item.image_url} />
                        </Link>
                    ))}
                </CardBox>
            </BodyContent>
            <Footer height={clientHeight}>
                <div className="contents">
                    <div className="footer_logo">
                        <img src="./logo/Galactic.png" alt="Galactic_logo" />
                    </div>
                    <FooterInfo>
                        <div className="top">
                            <span>(주)갤럭틱엔터테인먼트</span>
                            <span>대표자명 : 김소연, 잭 클레이</span>
                            <span>
                                서울특별시 용산구 이태원로 172, 303~304호
                                (스튜디오 오스카)
                            </span>
                            <span>TEL : 02-6375-1487</span>
                        </div>
                        <div>
                            <span>
                                © 2024 by Galactic Entertainment Inc., All
                                Rights Reserved.
                            </span>
                        </div>
                    </FooterInfo>
                    <div className="personal">
                        <a
                            href="https://docs.google.com/document/d/1Xj5iuNmyzOn7jr19Uegv12PKEkAMFY8Wp-4ZltMyvUE/view"
                            target="_blank"
                            rel="noreferrer"
                        >
                            서비스 이용약관
                        </a>
                        <a
                            href="https://docs.google.com/document/d/12b_Eyq7fyZpsz2Da9RI8OFYvgDDAN4Zf2OOokcD0dMc/view"
                            target="_blank"
                            rel="noreferrer"
                        >
                            개인정보처리방침
                        </a>
                    </div>
                </div>
            </Footer>
        </Layout>
    )
}

export default Main

const Layout = styled.div`
    margin: 0 auto;
`

const Header = styled.div`
    display: flex;
    justify-content: center;
    margin: 50px 0;
    h1 {
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
    }
`

const BodyContent = styled.div`
    // max-width: 100%;
    width: 1000px;
    margin: 0 auto;
`

const CardBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 50px;

    margin-bottom: 94px;
    box-sizing: border-box;

    a {
        color: #014680;
        text-align: center;
    }
`

const Footer = styled.div<{ height: number }>`
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid ${colors.gray};
    box-shadow: 0px -2px 3px rgba(211, 211, 211, 0.5);
    @media (max-height: ${({ height }) => height}px) {
        position: relative;
        // bottom: 0;
    }
    .contents {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1000px;
        height: 80px;
        margin: 0 auto;
        background: #fefefe;

        box-sizing: border-box;

        .footer_logo {
            img {
                max-width: 60px;
                max-height: 60px;
            }
        }
        .personal {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            // height: 100%;
            width: 220px;
            a {
                color: #478bea;
                font-size: 14px;
                line-height: 17px;
                text-decoration: none;
                &:hover {
                    color: blue;
                }
            }
        }
    }
`

const FooterInfo = styled.div`
    .top {
        margin-bottom: 8px;
    }
    .bottom {
    }
    & span {
        margin-right: 14px;
        color: ${colors.textGray};
        font-size: 12px;
        line-height: 14px;
    }
`
