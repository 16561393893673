import React, { useCallback, useEffect, useState } from 'react'
import AppLayout from '../components/AppLayout'
import TypeButton from '../components/elements/TypeButton'
import styled from '@emotion/styled'
import { get, post } from '../api/customAxios'
import { RecruitDetail } from '../types/Recruit'
import { useAppDispatch } from '../store'
import { AxiosError } from 'axios'
import { ErrorMessage } from '../types/Error'
import InfoForm from '../components/operationInfo/InfoForm'
import useInputs from '../hooks/useInputs'
import InfoAgree from '../components/operationInfo/InfoAgree'
import Indicator from '../components/Indicator'
import ResultModal from '../components/operationInfo/modal/ResultModal'
import { useSearchParams } from 'react-router-dom'

export type ErrorCheck = {
    resident_valid: boolean // 주민번호 정상 여부
    resident_message: string // 주민번호 에러 메세지
    bank_name_valid: boolean // 은행명 정상 여부
    bank_name_message: string // 은행명 에러 메세지
    holder_name_valid: boolean // 예금주 정상 여부
    holder_name_message: string // 예금주 에러 메세지
    account_number_valid: boolean // 계좌번호 정상 여부
    account_number_message: string // 계좌번호 에러 메세지
}

const Recruit = () => {
    const hash = window.location.hash
    // const decodeCode = Number(atob(hash.substring(1)))
    // const recruitNo = Math.floor(decodeCode / 10000000)
    // const applicantNo = decodeCode % 10000000

    // const decodeCode = Number(atob(hash.substring(1)))
    const [recruitNo, setRecruitNo] = useState(0)
    const [applicantNo, setApplicantNo] = useState(0)

    const dispatch = useAppDispatch()
    // 네트워크 처리 여부
    const [isLoading, setLoading] = useState(false)
    // recruit 정보 가져와야함
    const [checkAll, setCheckAll] = useState(false)
    // 제출 완료 안내
    const [showModal, setShowModal] = useState(false)
    // 에러 체크 용 변수 묶음
    const [errorCheck, setErrorCheck] = useState<ErrorCheck>({
        resident_valid: true, // 주민번호 정상 여부
        resident_message: '', // 주민번호 에러 메세지
        bank_name_valid: true, // 은행명 정상 여부
        bank_name_message: '', // 은행명 에러 메세지
        holder_name_valid: true, // 예금주 정상 여부
        holder_name_message: '', // 예금주 에러 메세지
        account_number_valid: true, // 계좌번호 정상 여부
        account_number_message: '', // 계좌번호 에러 메세지
    })

    // 폼 입력 값
    const [operationInfo, changeOperationInfo, setOperationInfo] = useInputs({
        resident_first: '', // 주민번호 앞자리
        resident_last: '', // 주민번호 뒷자리
        bank_name: '', // 은행명
        holder_name: '', // 예금주
        account_number: '', // 계좌 번호
        medical_benefit_yn: false, // 의료금여 수급자 여부
    })

    const [recruitDetail, setRecruitDetail] = useState<RecruitDetail>({
        recruit_no: 0,
        start_date: '',
        end_date: '',
        recruit_name: '',
        visibility: false,
        employ_count: 0,
        image_url: '',
        first_start_date: '',
        first_end_date: '',
        apply_type: [],
        second_start_date: '',
        second_end_date: '',
        recruit_context: '',
        announce_date: '',
        bg_color: '',
    })

    const handleRecruitDetailApi = async (rNo: number) => {
        try {
            const res = await get<RecruitDetail>(
                `${process.env.REACT_APP_PREFIX}/recruits/${rNo}`,
            )

            setRecruitDetail(res.data)
        } catch (e) {
            const { response } = e as unknown as AxiosError
            alert((response?.data as ErrorMessage)?.detail)
        }
    }

    const checkJumin = (strJumin: string) => {
        let total = 0
        const jumin = strJumin.replace('-', '').split('')
        if (['1', '2', '3', '4'].indexOf(jumin[6]) === -1) return true // 외국인은 길이만 체크
        const lastNum = parseInt(jumin[jumin.length - 1])
        for (let i = 0; i < jumin.length; i++) {
            if (0 <= i && i < 8) {
                total += parseInt(jumin[i]) * (i + 2)
            } else if (7 < i && i < 12) {
                total += parseInt(jumin[i]) * (i - 6)
            } else {
                continue
            }
        }
        const checkNum = (11 - (total % 11)) % 10
        return lastNum === checkNum
    }

    // 전송 처리
    const sendOperationInfo = async () => {
        const newErrorCheck = {
            resident_valid: true, // 주민번호 정상 여부
            resident_message: '', // 주민번호 에러 메세지
            bank_name_valid: true, // 은행명 정상 여부
            bank_name_message: '', // 은행명 에러 메세지
            holder_name_valid: true, // 예금주 정상 여부
            holder_name_message: '', // 예금주 에러 메세지
            account_number_valid: true, // 계좌번호 정상 여부
            account_number_message: '', // 계좌번호 에러 메세지
        }
        let hasError = false

        // 주민 번호 검증
        if (
            operationInfo.resident_first.length !== 6 &&
            operationInfo.resident_last.length !== 7
        ) {
            newErrorCheck.resident_message =
                '주민번호 길이가 맞지 않습니다. 다시 입력바랍니다.'
            newErrorCheck.resident_valid = false
            hasError = true
        }
        if (
            !checkJumin(
                operationInfo.resident_first +
                    '-' +
                    operationInfo.resident_last,
            )
        ) {
            newErrorCheck.resident_message =
                '유효한 주민번호가 아닙니다. 다시 입력바랍니다.'
            newErrorCheck.resident_valid = false
            hasError = true
        }
        // 은행명 검증 (2글자 이상)
        if (operationInfo.bank_name.length < 2) {
            newErrorCheck.bank_name_message = '다시 입력바랍니다.'
            newErrorCheck.bank_name_valid = false
            hasError = true
        }
        // 예금주 검증 (2글자 이상)
        if (operationInfo.holder_name.length < 2) {
            newErrorCheck.holder_name_message = '다시 입력바랍니다.'
            newErrorCheck.holder_name_valid = false
            hasError = true
        }
        // 계좌번호 검증 (13글자 이상)
        if (operationInfo.account_number.length < 13) {
            newErrorCheck.account_number_message =
                '계좌번호 길이가 짧습니다. -도 값이 입력해주시기 바랍니다.'
            newErrorCheck.account_number_valid = false
            hasError = true
        }

        if (operationInfo.resident_first) setErrorCheck(newErrorCheck)
        // 전송 처리
        if (!hasError) {
            try {
                setLoading(true)
                const res = await sendOperationInfoApi()
                if (res) {
                    setShowModal(true)
                }
            } catch (e) {
            } finally {
                setLoading(false)
            }
        }
    }

    const sendOperationInfoApi = async () => {
        return new Promise(async (resolve, reject) => {
            const payload = {
                resident_number: `${operationInfo.resident_first}-${operationInfo.resident_last}`,
                bank_name: `${operationInfo.bank_name}`,
                holder_name: `${operationInfo.holder_name}`,
                account_number: `${operationInfo.account_number}`,
                medical_benefit_yn: operationInfo.medical_benefit_yn,
            }
            try {
                await post(
                    `${process.env.REACT_APP_PREFIX}/applicants/privacy/${applicantNo}`,
                    payload,
                )
                resolve(true)
            } catch (e) {
                const { response } = e as unknown as AxiosError
                const detail = (response?.data as ErrorMessage)?.detail
                if (detail) {
                    alert(detail)
                } else {
                    alert(
                        '제출에 실패 하였습니다. 잘못된 주소이거나 일시적인 네트워크 장애일수있습니다. 지속적으로 오류가 발생한다면 담당자에게 문의 주시기 바랍니다.',
                    )
                }
                // alert((response?.data as ErrorMessage)?.detail)
            }
            resolve(false)
        })
    }

    useEffect(() => {
        let rNo = null
        let aNo = null
        try {
            const decodeCode = Number(atob(hash.substring(1)))
            rNo = Math.floor(decodeCode / 10000000)
            aNo = decodeCode % 10000000
            setRecruitNo(rNo)
            setApplicantNo(aNo)
        } catch (e) {}

        if (!rNo) {
            alert('잘못된 접근 입니다. 주소를 다시 확인해주세요')
            window.history.go(-1)
        } else {
            handleRecruitDetailApi(rNo)
        }
        return () => {}
    }, [])

    useEffect(() => {
        // 변경 핸들러를 중간에 체가서 처리함
        if (!operationInfo.resident_first) {
            return setCheckAll(false)
        }
        if (!operationInfo.resident_last) {
            return setCheckAll(false)
        }
        if (!operationInfo.bank_name) {
            return setCheckAll(false)
        }
        if (!operationInfo.holder_name) {
            return setCheckAll(false)
        }
        if (!operationInfo.account_number) {
            return setCheckAll(false)
        }
        if (!operationInfo.agreement) {
            return setCheckAll(false)
        }
        setCheckAll(true)
    }, [operationInfo])

    useEffect(() => {}, [recruitDetail.recruit_no])

    return (
        <AppLayout
            bgColor={recruitDetail.bg_color}
            src={`http://${recruitDetail.image_url}`}
        >
            <Layout>
                <h2>운영필수정보 수집 안내</h2>
                <div className="guide">
                    <p>
                        주식회사 갤럭틱엔터테인먼트(이하
                        ‘(주)갤럭틱엔터테인먼트’)는 본 공고의 주관 기관으로부터
                        사업을 위탁받아 운영중입니다.
                        <br />
                        이에 따라 (주)갤럭틱엔터테인먼트 소속의 근로자 채용에
                        따른 입사 처리, 임금 대장 작성, 사회 4대보험 가입 및
                        급여 지급 등의 인사·급여 행정 업무를 처리하고자 <br />
                        아래 개인정보처리방침에 따라 개인정보를 수집하고
                        있사오니 아래 내용에 대해 작성 후 제출해주시기 바랍니다.
                        <br />
                        <br />
                        이에 대한 관련 법령은 다음과 같습니다.
                        <br />
                        &nbsp; 「근로기준법」 제48조(임금대장)
                        <br />
                        &nbsp; 「소득세법」 제27조(임금대장의 기재사항)
                        <br />
                        &nbsp; 「장애인 고용촉진 및 직업재활법」 제11조(장애인
                        고용계획 등의 제출)
                    </p>
                </div>

                <h3>개인정보 제공·활용 동의 (필수)</h3>
                <InfoAgree
                    operationInfo={operationInfo}
                    change={changeOperationInfo}
                    setter={setOperationInfo}
                />

                <h3>
                    운영필수정보
                    <span className="required">
                        *표시가 된 칸은 필수로 입력해야합니다
                    </span>
                </h3>
                <InfoForm
                    operationInfo={operationInfo}
                    change={changeOperationInfo}
                    setter={setOperationInfo}
                    errorCheck={errorCheck}
                />

                <Indicator isLoading={isLoading} top={0} />
                <div>
                    <TypeButton
                        type={'confirm'}
                        icon={false}
                        sort={true}
                        disabled={!checkAll}
                        onClick={sendOperationInfo}
                    >
                        전송하기
                    </TypeButton>
                </div>
            </Layout>
            {showModal && <ResultModal setShowModal={setShowModal} />}
        </AppLayout>
    )
}

export default Recruit

const Layout = styled.div`
    margin: 84px 0 70px 0;
    font-family: 'Pretendard';
    position: relative;

    .guide {
        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: #333333;
        }
    }

    h2 {
        margin-bottom: 24px;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        color: #333333;
    }

    h3 {
        margin: 50px 0 0 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #333333;

        span.required {
            margin-left: 16px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.003em;
            color: #ff5b48;
            vertical-align: middle;
        }
    }
`
