import ReactGA from 'react-ga4'

export const callGtag = (pageName: string, pagePath: string) => {
    const trakingId = process.env.REACT_APP_TRACKING_ID + ''
    console.log('trakingId', trakingId)
    console.log('pageName', pageName, 'pagePath', pagePath)
    ReactGA.initialize(trakingId)
    // ReactGA.set({ page: pagePath }) // Update the user's current page
    // ReactGA.pageview(pageName) // Record a pageview for the given page
    ReactGA.send({ hitType: 'pageview', page: pagePath, title: pageName })
}
